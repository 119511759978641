<template>
  <div>
  <center>
    <h1>Streamlabs Callback</h1>
    Streamlabs is now linked.
    <img src="https://cdn.frankerfacez.com/emoticon/210748/1">
    You may close this tab.
    </center>
  </div>
</template>

<script>
export default {
    data() {
        return {
            code: ''
        }
    },
mounted () {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('code');
  this.code = myParam;
  this.linkStreamlabs(myParam);
}
}
</script>

<style>

</style>